@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
    font-family: Poppins;
    margin: 0;
    padding: 0;
}
.clientImage {
    justify-content: center;
    align-items: center;
    display: flex;
    margin: 6vw 4vw;
}

.wrapper {
    overflow: hidden;
    position: relative;
    width: 80vw;
    align-items: center;
    display: flex;
    -webkit-transform: translateZ(0);
            transform: translateZ(0)
}

.marquee {
    -webkit-animation: scrolling 20s linear infinite;
            animation: scrolling 20s linear infinite;
    display: flex;
}

@-webkit-keyframes scrolling {
    0% {
        -webkit-transform: translateX(0%);
                transform: translateX(0%);
    }

    100% {
        -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
    }
}

@keyframes scrolling {
    0% {
        -webkit-transform: translateX(0%);
                transform: translateX(0%);
    }

    100% {
        -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
    }
}
.Button_buttonStyles__2kUxw {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    outline: none;
}

.Button_disabledButtonCursor__1-0zg {
    cursor: no-drop;
}

.Button_opacityOnDisable__x5J0w {
    opacity: 0.5;
}

.gmap_canvas {
    overflow: hidden;
    background: none !important;
    height: 500px;
    width: 600px;
}

@media screen and (max-width: 1400px) {
    .gmap_canvas {
        overflow: hidden;
        background: none !important;
        height: 450px;
        width: 550px;
    }
}

@media screen and (max-width: 1300px) {
    .gmap_canvas {
        overflow: hidden;
        background: none !important;
        height: 400px;
        width: 500px;
    }
}

@media only screen and (min-width: 350px) and (max-width: 600px) {
    .gmap_canvas {
        overflow: hidden;
        background: none !important;
        height: 300px;
        width: 90%;
    }
}
.inputStyles {
    border-radius: 6px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #14142b;
    padding: 0 12px;
    display: flex;
    flex-direction: row;
    grid-gap: 12px;
    gap: 12px;
    align-items: center;
    height: 48px;
    border: 1px solid #d1d4d7;
    outline: none;
}

.input::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #6e7191;
}

.input::-moz-placeholder {
    /* Firefox 19+ */
    color: #6e7191;
}

.input:-ms-input-placeholder {
    /* IE 10+ */
    color: #6e7191;
}

.input:-moz-placeholder {
    /* Firefox 18- */
    color: #6e7191;
}
.textarea {
    border-radius: 6px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #14142b;
    padding: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid #d1d4d7;
    outline: none;
}

.textarea::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #6e7191;
}

.textarea::-moz-placeholder {
    /* Firefox 19+ */
    color: #6e7191;
}

.textarea:-ms-input-placeholder {
    /* IE 10+ */
    color: #6e7191;
}

.textarea:-moz-placeholder {
    /* Firefox 18- */
    color: #6e7191;
}
.commonStyles {
    background-color: rgb(0, 0, 0, 0.8);
    z-index: 10;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.modal {
    /* composes: commonStyles; */
    z-index: 10;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modalHalfVariant {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: 100%;
}

.modalContent {
    background-color: white;
    width: 200px;
    height: 200px;
    margin: auto;
    padding: 0px;
    border-radius: 16px;
}

.modalContentHalfVariant {
    width: 100%;
    max-width: 570px;
    height: auto;
    padding: 0px;
    background: #ffffff;
    border-radius: 16px 16px 0px 0px;
    position: relative;
}

.closeModal {
    display: flex;
    position: absolute;
    top: -40px;
    left: 0;
    right: 0;
    align-items: center;
    justify-content: center;
    margin: auto;
    cursor: pointer;
}

@-webkit-keyframes animationInFromBottom {
    0% {
        -webkit-transform: translateY(100%);
                transform: translateY(100%);
    }
    100% {
        -webkit-transform: translateY(0%);
                transform: translateY(0%);
    }
}

@keyframes animationInFromBottom {
    0% {
        -webkit-transform: translateY(100%);
                transform: translateY(100%);
    }
    100% {
        -webkit-transform: translateY(0%);
                transform: translateY(0%);
    }
}

@-webkit-keyframes animationInFromTop {
    0% {
        -webkit-transform: translateY(0%);
                transform: translateY(0%);
    }
    100% {
        -webkit-transform: translateY(100%);
                transform: translateY(100%);
    }
}

@keyframes animationInFromTop {
    0% {
        -webkit-transform: translateY(0%);
                transform: translateY(0%);
    }
    100% {
        -webkit-transform: translateY(100%);
                transform: translateY(100%);
    }
}

@-webkit-keyframes animationLeftFromRightKeyFrame {
    0% {
        -webkit-transform: translate(0, 0);
                transform: translate(0, 0);
    }
    100% {
        -webkit-transform: translate(100%, 0);
                transform: translate(100%, 0);
    }
}

@keyframes animationLeftFromRightKeyFrame {
    0% {
        -webkit-transform: translate(0, 0);
                transform: translate(0, 0);
    }
    100% {
        -webkit-transform: translate(100%, 0);
                transform: translate(100%, 0);
    }
}

@-webkit-keyframes animationRightFromLeftKeyFrame {
    0% {
        -webkit-transform: translate(100%, 0);
                transform: translate(100%, 0);
    }
    100% {
        -webkit-transform: translate(0, 0);
                transform: translate(0, 0);
    }
}

@keyframes animationRightFromLeftKeyFrame {
    0% {
        -webkit-transform: translate(100%, 0);
                transform: translate(100%, 0);
    }
    100% {
        -webkit-transform: translate(0, 0);
                transform: translate(0, 0);
    }
}

.animationFromBottomToTop {
    transition: all 0.8s;
    -webkit-animation: 1s ease-out 0s 1 animationInFromTop;
            animation: 1s ease-out 0s 1 animationInFromTop;
}

.animationFromTopToBottom {
    transition: all 0.8s;
    -webkit-animation: 1s ease-out 0s 1 animationInFromBottom;
            animation: 1s ease-out 0s 1 animationInFromBottom;
}

.animationRightToLeft {
    transition: all 0.8s;
    -webkit-animation: 1s ease-out 0s 1 animationRightFromLeftKeyFrame;
            animation: 1s ease-out 0s 1 animationRightFromLeftKeyFrame;
}

.animationLeftToRight {
    transition: all 0.8s;
    -webkit-animation: 1s ease-out 0s 1 animationLeftFromRightKeyFrame;
            animation: 1s ease-out 0s 1 animationLeftFromRightKeyFrame;
}
.briefHistoryGrid {
    display: flex;
    flex-flow: column nowrap;
    box-sizing: border-box;
    margin: 0 auto;
    max-width: 960px;
    padding-left: 16px;
    padding-right: 16px;
    position: relative;
}
.stickyHeader {
    display: flex;
    align-items: center;
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    z-index: 9;
    background-color: rgb(255, 255, 255);
}
