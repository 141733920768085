.textarea {
    border-radius: 6px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #14142b;
    padding: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid #d1d4d7;
    outline: none;
}

.textarea::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #6e7191;
}

.textarea::-moz-placeholder {
    /* Firefox 19+ */
    color: #6e7191;
}

.textarea:-ms-input-placeholder {
    /* IE 10+ */
    color: #6e7191;
}

.textarea:-moz-placeholder {
    /* Firefox 18- */
    color: #6e7191;
}