.commonStyles {
    background-color: rgb(0, 0, 0, 0.8);
    z-index: 10;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.modal {
    /* composes: commonStyles; */
    z-index: 10;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modalHalfVariant {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: 100%;
}

.modalContent {
    background-color: white;
    width: 200px;
    height: 200px;
    margin: auto;
    padding: 0px;
    border-radius: 16px;
}

.modalContentHalfVariant {
    width: 100%;
    max-width: 570px;
    height: auto;
    padding: 0px;
    background: #ffffff;
    border-radius: 16px 16px 0px 0px;
    position: relative;
}

.closeModal {
    display: flex;
    position: absolute;
    top: -40px;
    left: 0;
    right: 0;
    align-items: center;
    justify-content: center;
    margin: auto;
    cursor: pointer;
}

@keyframes animationInFromBottom {
    0% {
        transform: translateY(100%);
    }
    100% {
        transform: translateY(0%);
    }
}

@keyframes animationInFromTop {
    0% {
        transform: translateY(0%);
    }
    100% {
        transform: translateY(100%);
    }
}

@keyframes animationLeftFromRightKeyFrame {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(100%, 0);
    }
}

@keyframes animationRightFromLeftKeyFrame {
    0% {
        transform: translate(100%, 0);
    }
    100% {
        transform: translate(0, 0);
    }
}

.animationFromBottomToTop {
    transition: all 0.8s;
    animation: 1s ease-out 0s 1 animationInFromTop;
}

.animationFromTopToBottom {
    transition: all 0.8s;
    animation: 1s ease-out 0s 1 animationInFromBottom;
}

.animationRightToLeft {
    transition: all 0.8s;
    animation: 1s ease-out 0s 1 animationRightFromLeftKeyFrame;
}

.animationLeftToRight {
    transition: all 0.8s;
    animation: 1s ease-out 0s 1 animationLeftFromRightKeyFrame;
}