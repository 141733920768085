.clientImage {
    justify-content: center;
    align-items: center;
    display: flex;
    margin: 6vw 4vw;
}

.wrapper {
    overflow: hidden;
    position: relative;
    width: 80vw;
    align-items: center;
    display: flex;
    transform: translateZ(0)
}

.marquee {
    animation: scrolling 20s linear infinite;
    display: flex;
}

@keyframes scrolling {
    0% {
        transform: translateX(0%);
    }

    100% {
        transform: translateX(-50%);
    }
}