.gmap_canvas {
    overflow: hidden;
    background: none !important;
    height: 500px;
    width: 600px;
}

@media screen and (max-width: 1400px) {
    .gmap_canvas {
        overflow: hidden;
        background: none !important;
        height: 450px;
        width: 550px;
    }
}

@media screen and (max-width: 1300px) {
    .gmap_canvas {
        overflow: hidden;
        background: none !important;
        height: 400px;
        width: 500px;
    }
}

@media only screen and (min-width: 350px) and (max-width: 600px) {
    .gmap_canvas {
        overflow: hidden;
        background: none !important;
        height: 300px;
        width: 90%;
    }
}