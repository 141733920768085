.buttonStyles {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    outline: none;
}

.disabledButtonCursor {
    cursor: no-drop;
}

.opacityOnDisable {
    opacity: 0.5;
}
